import * as React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"

const contact = () => {
  return (
    <>
      <Seo title="Contact" />
      <Layout>
        <SimpleBanner title="About Me">
          <StaticImage
            className="banner__image"
            src="https://imgur.com/dzgbVXu.jpg"
            alt="Selfie red"
          />
        </SimpleBanner>
        <div class="section">
          <div class="container container__tight">
            <div>
              <p>
                Raised in London, I’ve been surrounded by music all my life.
                Whether it be the bassy blast from passing cars, the back of TFL
                buses, or from my dad’s DIY music studio, music has been
                inescapable.
              </p>
              <p>
                My musical journey began at home with my dad who is a
                Guitarist/Saxophonist. Learning the Guitar with him and having a
                stab at using Nuendo on his old WIN XP desktop, trying to
                recreate music from my favourite video games (Usually Zelda or
                Final Fantasy) is what sparked that flare.
              </p>
              <p>
                Secondary school is where it went further, joining the ‘MIST’
                internal music specialist program through which I had to learn
                an orchestral instrument. At the ignorant age of about 12, I
                chose what I thought was the shiniest, easiest transition from
                the recorder (which I was learning in primary school) - the
                flute. I quickly discovered the deceptively innocent looking
                flute, was entirely distinct from the recorder and would be far
                more demanding than I’d imagined, which also laid the strong
                foundations for my musical practice. I studied orchestral music
                and the flute for several years before achieving an ABRSM Grade
                6.
              </p>
              <p>
                Around this time, a growing interest in rock & metal music began
                drawing me toward a very different instrument. I would hear the
                drummer in my class playing in school practise rooms, and would
                often find myself tapping along. Eventually, my curiosity
                bewitched me and I approached him during one of his practice
                sessions…I was hooked thereafter… I would spend hours playing,
                to the point that the caretaker would kick me out with an
                expression that couldn’t be interpreted as anything other than:
                “don’t you have a home to go to?”.
              </p>
              <p>
                Months later, after attaining some fluency on the kit, I joined
                the school band and began learning rock and funk records,
                performing at school concerts and events. This led to me sitting
                in on a track or two at my dad’s gigs from time to time. My
                passion and love for it began to grow faster than the mercury in
                a thermometer stirring an ice blast. Hence, I started taking
                formal lessons from Neil Findlay, drummer from ‘The Curse of
                Lono’.
              </p>
              <p>
                This continued into sixth form where completed my Music and
                Music Technology A levels, and began becoming proficient in
                using digital audio workstations for recording, producing,
                mixing and mastering music, picking up odd jobs as a sound
                engineer.
              </p>
              <p>
                Once at the Academy of Contemporary music where I achieved my
                BAHons in Music, I began learning under Darren Beckett, New York
                jazz drummer who has worked with ‘The Killers’ and George
                Colligan. I also learnt from George Cook, contemporary session
                drummer who has worked with the likes of Eminem and ‘The
                Japanese House’. I eventually studied with Matt Hector, drummer
                for Iggy pop and ‘Razorlight’. These beasts, were all big
                inspirations to my playing and each added a new dimension to my
                drumming allowing me to begin honing genres such as Jazz/ Latin,
                Hip Hop, Motown, Blues and much more.
              </p>
              <p>
                Concurrently, I began playing and producing professionally.
                Having now played for over 10 years, I have worked with: Keny
                Charles, Jazz/Funk/Soul band. Keny has worked with and supported
                the likes of George Benson and Lionel Richie. Derrick Mcintyre,
                Jamiroquai bassist. Jazzetude - Jazz and fusion band at venues
                across London such as the Jazz Cafe. The Days Run, an indie rock
                band in which I have played on and mixed multiple singles as
                well as toured with them across London and the UK. I have worked
                with FRNDS as CMPNY, electronic music producer/singer. Andy
                Metcalfe, bassist for Squeeze & The Soft Boys who has worked
                with Jools Holland. We played predominantly in a Gypsy Jazz band
                called ‘The Garcons’, across London. Flytrap who are a soul and
                funk function band, through which I have played with Amro Salah,
                legendary Jazz pianist who runs the Cairo Jazz Festival.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default contact
